.project-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: min(90%, 700px);
    margin: 3em 0 3em;
}

.card-img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: min(30vh, 300px);
    border-radius: 20px 20px 0 0;
}

.card-info {
    background-color: var(--secondary-middle);
    padding: 20px;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0 10px 15px black;
}

.card-info h3 {
    font-size: var(--font-medium);
    margin-bottom: 12px;
    letter-spacing: 1px;
}

.card-info p {
    font-size: var(--font-small);
    margin-bottom: 8px;
}

.note {
    font-style: italic;
}

.card-btn {
    display: inline-block;
    border: none;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    background-color: var(--contrast);
    padding: 5px;
    color: black;
    border-radius: 5px;
    margin: 10px 10px 0 0;
    font-size: var(--font-small);
    transition: transform 0.3s ease;
    letter-spacing: 2px;
}

.card-btn:hover {
    transform: scale(1.05) translateZ(0);
}