.home {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
}

.container-home {
    position: relative;
    padding-top: 10vh;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--m-1);
}

.jakob {
    -webkit-text-stroke: 1px rgb(93, 99, 9);
    color: var(--contrast);
}

.container-socials {
    display: flex;
    margin-top: var(--m-1);
    gap: var(--gap-standard);
}

.social-logo {
    text-decoration: none;
    color: white;
    cursor: pointer;
    color: var(--secondary-light);
}

.container-info {
    display: flex;
    flex-direction: column;
    gap: var(--gap-standard);
    width: min(90%, 700px);
}

.info-intro {
    font-size: clamp(1.5em, 1vw + 1.5rem, 3em);
    line-height: 4.4vh
}

.info-details {
    font-size: clamp(1.2em, 1vw + .8rem, 2em);
    line-height: 3.5vh;
}

.connect {
    position: relative;
    top: 0;
    transition: top ease 0.2s;
    text-decoration: none;
    color: white;
    margin: var(--m-3);
    padding: var(--p-1);
    border-radius: 10px;
    background-color: var(--secondary-middle);
    box-shadow: 0 3px 5px rgb(142, 142, 142);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.connect:hover {
    cursor: pointer;
    top: -5px;
}