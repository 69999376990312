.container-skills {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tech-container-title {
    font-size: var(--font-medium);
    margin: 1em 0 1em;
}

.tech-container {
    display: flex;
    flex-direction: column;
    width: min(90%, 700px);
    margin-bottom: 3em;
}

.container-languages {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    background-color: var(--secondary-middle);
    border-radius: 20px;
    padding: var(--p-2);
    box-shadow: 0 10px 15px black;
}

@media screen and (max-width: 40em) {
    .container-languages {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 21em) {
    .container-languages {
        grid-template-columns: repeat(1, 1fr);
    }
}