.container-lang {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--m-1);
    background-color: var(--secondary-washed);
    border-radius: 10px;
    padding: var(--p-1);
    box-shadow: 0 8px 8px rgb(0, 0, 0);
    font-size: clamp(.1em, 1vw + 1rem, .9em);
}

.container-lang svg {
    width: 70px;
    margin-bottom: 10px;
}

