.container-aboutme {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container-about-info {
    width: min(90%, 700px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--gap-standard);
    line-height: 30px;
}

.container-about-info p {
    font-size: var(--font-small);
}

.container-about-knowledge {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: min(90%, 700px);
    gap: var(--gap-standard);
    margin: 3em 0 3em;
}

.resume-link {
    text-decoration: none;
}

.button-resume {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: var(--p-1);
    margin: var(--m-2);
    font-size: var(--font-small);
    width: 150px;
    color: white;
    outline: none;
    border: 2px solid black;
    /* background-color: var(--secondary); */
    border-radius: 10px;
    background-color: black;
    box-shadow: inset 0 0 0 0 var(--contrast);
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
}

.slide-right:hover {
    cursor: pointer;
    color: black;
    box-shadow: inset 400px 0 0 0 var(--contrast);
}

/* medium */
@media screen and (min-width: 48em) {
    .container-about-knowledge {
        flex-direction: row;
        justify-content: space-around;
    }
}