.container-navbar {
    z-index: 300000;
    width: 100vw;
    height: 10vh;
    top: 0;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary);
}

.container-logo {
    margin-left: 20px;
}

.navbar-initial {
    color: var(--contrast);
}

.navbar-name {
    font-size: clamp(var(--font-small), 1vw + 1rem, var(--font-medium));
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.nav-item {
    padding: 1em;
    font-size: var(--font-small);
}

.home-link {
    text-decoration: none;
    color: white;
}

.nav-link {
    text-decoration: none;
    color: white;
    position: relative;
    transition: .4s;
    padding: .3em;
    border-radius: 10px;
    transition: .4s ease-out;
}

.nav-link::after {
    content: "";
    position: absolute;
    background-color: var(--contrast);
    height: 3px;
    border-radius: 10px;
    display: block;
    width: 0;
    left: 0;
    bottom: -2px;
    transition: 0.3s;
}

.nav-link:hover:after {
    width: 100%;
}

.hamburger {
    display: none;
    cursor: pointer;
}

.bar {
    display: block;
    width: 30px;
    height: 3px;
    margin: 6px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: var(--contrast);
}

@media all and (max-width: 1201px) {

    .navbar.active {
        position: fixed;
        width: 100vw;
    }

    .nav-menu {
        position: fixed;
        left: -200%;
        top: 10vh;
        gap: 0;
        height: 90vh;
        flex-direction: column;
        justify-content: flex-start;
        background-color: var(--primary);
        width: 100%;
        text-align: center;
        transition: 0.3s;
        opacity: .96;
    }

    .nav-item {
        margin: 16px 0;
        font-size: 1.5em;
    }

    .nav-menu.active {
        left: 0;
    }

    .hamburger {
        display: block;
        margin-right: 20px;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(9px) rotate(45deg)
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-9px) rotate(-45deg)
    }

}