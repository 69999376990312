form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-field {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.form-input {
    font-family: Russo;
    background-color: var(--secondary);
    border: none;
    border-bottom: 4px solid black;
    padding: 12px;
    font-size: var(--font-small);
    box-shadow: 0 0 20px rgb(0, 0, 0);
    color: var(--contrast);
    border-radius: 10px;
}

.form-input:focus {
    outline: none;
    border-bottom: 4px solid var(--contrast);
}

.form-label {
    font-size: var(--font-small);
}

.form-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: var(--secondary);
    outline: none;
    border: none;
    padding: var(--p-1);
    color: white;
    font-size: 1.3em;
    margin-bottom: 2em;
    cursor: pointer;
    border-radius: 10px;
    background-color: black;
    box-shadow: inset 0 0 0 0 var(--contrast);
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
}

.slide-right:hover {
    cursor: pointer;
    color: black;
    box-shadow: inset 400px 0 0 0 var(--contrast);
}


form button:hover {
    cursor: pointer;
}

.error-form {
    margin: var(--m-1);
    color: rgb(208, 70, 70);
}