.contact {
    position: relative;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    /* flex-grow: 1; */
}

.container-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--p-1);
    border-radius: 20px;
    width: min(90%, 700px);
    height: 100%;
}

.contact-info{
    display: flex;
    gap: 10px;
}

.sub-container {
    display: flex;
    flex-direction: column;
}

.info-child-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.container-form {
    width: min(100%, 400px);
}
