/* CSS RESET */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*******************************************/

:root {
  --primary: black;
  --secondary: rgb(29, 29, 29);
  --secondary-middle: rgb(50, 50, 50);
  --secondary-light: white;
  --secondary-washed: rgb(108, 108, 108);
  --contrast: rgb(217, 196, 39);

  --m-1: 10px;
  --m-2: 20px;
  --m-3: 30px;
  --m-4: 40px;
  --m-5: 50px;

  --p-1: 10px;
  --p-2: 20px;
  --p-3: 30px;
  --p-4: 40px;
  --p-5: 50px;

  --font-large: 3em;
  --font-medium: 2em;
  --font-small: 1.2em;

  /* --max-width-text: 90%; */

  --gap-standard: 20px;
}

@font-face {
  font-family: Russo;
  /* font-display: swap; */
  font-style: normal;
  src: url(./fonts/RussoOne-Regular.ttf);
}

@font-face {
  font-family: Starwars;
  /* font-display: swap; */
  font-style: normal;
  src: url(./fonts/Rubik-VariableFont_wght.ttf);
}


html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
  color: white;
}

body {
  width: 100%;
  background-color: var(--secondary);
  overflow-x: hidden;
  font-family: Russo;
}

button {
  font-family: Russo;
}

.aboutme,
.skills,
.projects,
.contact{
  margin: 3em 1em 7em;
}

.container-about-info,
.container-about-knowledge,
.container-coding-langs,
.project-card,
.container-contact {
  margin-top: 2em;
}


.title {
  font-size: clamp(var(--font-medium), 1vw + 2.5rem, var(--font-large));
  -webkit-text-stroke: 1px rgb(159, 151, 7);
  color: var(--contrast);
  margin: var(--m-4);
  z-index: 3;
}

.number-section {
  position: absolute;
  font-size: 8.5em;
  opacity: 1;
  color: var(--secondary-washed);
  top: 0;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: black;
}

::-webkit-scrollbar-thumb {
  background: var(--contrast);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
