.footer {
    margin: 0;
    background-color: black;
}

.container-footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    font-size: var(--font-small);
}

.footer-name {
    font-size: clamp(1em, 1vw + 2.5rem, 1em);
}

.footer-title {
    margin-top: .3em;
    font-size: clamp(.5em, 1vw + 2rem, .9em);
    text-align: center;
}

.container-contact-info {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    font-size: clamp(1em, 1vw + 2.5rem, 1.1em);
    margin: 1em .5em 1em;
}

.footer-item {
    list-style-type: none;
}

.footer-link {
    color: var(--secondary-middle);
    text-decoration: none;
}

@media screen and (max-width: 35em) {
    .container-contact-info {
        flex-direction: column;
    }
}