.container-engineer-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--gap-standard);
    background-color: var(--secondary-middle);
    box-shadow: 0 8px 20px black;
    padding: var(--p-4);
    border-radius: 20px;
}

.container-back-end p,
.container-front-end p {
    font-size: var(--font-small);
}